<template>
  <div class="mw-100 d-flex h-100 flex-column justify-content-between align-items-center">

    <div style="height:40%">  
      <img class=" mt-5 pt-5" width="135px" alt="Vue logo" src="../assets/logo.png">
    </div>
    <LoginButtons style="height:60%"></LoginButtons>
  </div>
</template>

<script>
// @ is an alias to /src

import LoginButtons from '@/components/login/LoginButtons.vue';

export default {
  name: 'HomeView',
  components: {
    LoginButtons
}
}
</script>
