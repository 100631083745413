<template>
    <form @submit.prevent="doLogin" class="d-flex flex-column justify-content-between h-100">
        <div class="surf-h1 text-center mb-5 pb-3">
            Welcome Back
        </div>
        <div>
            <div class="alert bg-danger" v-if="error">Email or password are incorrect!</div>
            <div class=" mb-4 mx-3">
                <input v-model="email" type="email" class="form-control" id="floatingInput" placeholder="Username">
            </div>
            <div class="  mb-2 mx-3">
                <input v-model="password" type="password" class="form-control" id="floatingPassword"
                    placeholder="***********">
            </div>
            <div class="d-flex justify-content-between mb-5 mx-3">
                <router-link to="/register" style="font-size:12px" class="text-decoration-none text-dark text-italic">Don't
                    have account? Sing up</router-link>
                <router-link to="/login" style="font-size:12px" class="text-decoration-none text-dark text-italic">Forget
                    password?</router-link>

            </div>


            <div class="col-10 offset-1 mb-1">
                <LoginViaFacebook></LoginViaFacebook>
            </div>

            <div class="col-10 offset-1 mb-3">
                <LoginViaGoogle></LoginViaGoogle>
            </div>
        </div>
        <div class="d-flex justify-content-center mb-2 w-100">
            <div class="col-6">
                <button class="btn btn-primary d-block w-100">Login</button>
            </div>
        </div>


    </form>
</template>
<script>
import appApi from './../api/appApi';
import LoginViaFacebook from './LoginViaFacebook.vue';
import LoginViaGoogle from './LoginViaGoogle.vue';

export default {
    props: [''],
    data() {
        return {
            email: "",
            password: "",
            error: false,
        };
    },
    methods: {
        doLogin: function () {
            this.error = false;
            appApi.login(this.email, this.password).then(response => {
                this.$store.commit('setUser', response.data.user);
                this.$router.push('/');
                this.password = '';
            }, () => {
                this.error = true;
                this.password = '';
            });
        }
    },
    components: { LoginViaFacebook, LoginViaGoogle }
}
</script>

