<template>
    <div class="position-relative" v-if="content">
        <img src="@/assets/icons/item_bg.svg" class="position-absolute w-100 ">

        <router-link @click.native="$emit('clicked')" :to="'/spot/' + item.slug"
            class="d-flex text-reset text-decoration-none  mb-3 my-list-item  mt- zindex-1 position-relative">
            <!-- <img src="@/assets/icons/certificate.svg" class="position-absolute end-0"> -->
            <div class="col-4 p-0">
                <img :src="content.field('default', 'spot-icon')" class="mw-100 rounded-4 item-image">
            </div>
            <div class="ms-4">
                <h6 class="text-white mb-3">{{ item.name }}</h6>
                <div class="d-flex gap-2">
                    <ContinentIcon v-tooltip :title="content.field('localization', 'continent')" :icon="content.field('localization', 'continent')"></ContinentIcon>
                    <img :alt="countryCode" v-tooltip :title="content.field('localization', 'country')" class="item-icon" :src="'/assets/country/' + country + '.svg'">
                    <img class="item-icon" v-if="content.field('sports', 'kitesurfing')==true" title="Kitesurfing" v-tooltip src="@/assets/icons/kitesurfing.svg">
                    <img class="item-icon" v-if="content.field('sports', 'windsurfing')==true" title="Windsurfing" v-tooltip src="@/assets/icons/sport1.svg">
                    <img class="item-icon" v-if="content.field('sports', 'wingfoling')==true" title="Wingfoling" v-tooltip src="@/assets/icons/wingfoiling.svg">

                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { useContent } from '@/helpers/content';
import ContinentIcon from '@/components/icons/ContinentIcon.vue'
export default {
    components: { ContinentIcon },
    props: ['item'],
    data() {
        return {
            content: null
        }
    },
    computed: {
        country: function () {
            if (!this.content) return '';
            const icon = this.content.field('localization', 'country')?.toLowerCase().replace(' ', '_');
            return icon ? icon : 'montenegro';
        },
        countryCode: function () {
            if (!this.content) return '';
            return this.content.field('localization', 'country')?.toUpperCase().substring(0, 2);
        }
    },
    mounted() {
        if (this.item.content_form_groups) {
            this.content = useContent(this.item.content_form_groups);
        }
        if (this.item.content_fields) {
            this.content = useContent(this.item.content_fields);
        }
    }
}
</script>