import {
    Tooltip
} from "bootstrap";
export default {
    install(app) {
        app.directive('tooltip', {
            // When the bound element is inserted into the DOM...
            inserted: function (el) {
                new Tooltip(el, {
                    trigger: 'hover',
                    boundary: document.body
                })
            },
            update: function (el) {
                setTimeout(function () {
                    if (el.title) {
                        el.setAttribute('data-bs-original-title', el.title);
                    }
                    //console.log(el);
                    Tooltip.getInstance(el).update();
                }, 250);
            }
        }
        );
    }

}