export function useContent(content_fields) {
    function field(group, key, def = '--') {
        if (!content_fields[group]) {
            return def
        }
        return content_fields[group][0][key];
    }
    function fieldArray(group) {
        if (!content_fields[group]) {
            return []
        }
        return content_fields[group];
    }

    function fieldArrayFirst(group) {
        if (!content_fields[group]) {
            return []
        }
        return content_fields[group][0] ? content_fields[group][0] : {};
    }
    return { field, fieldArray, fieldArrayFirst }
}