import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    logged: false,
    favourites: {},
    userdata: {},
  },
  getters: {
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
      state.logged = true;
    },
    setUserAvatar(state, data) {
      state.user.avatar = data;
    },
    setFavourites(state, data) {
      state.favourites = data;
    },
    setFavouritesSpot(state, data) {
      state.favourites.spot = data;
    },
    setFavouritesSchool(state, data) {
      state.favourites.school = data;
    },
    setUserdata(state, data) {
      state.userdata = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
