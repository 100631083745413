import axios from "axios";

const appAxios = axios.create({ baseURL: 'https://surfbuddy.szymonyo.tk/app/' })


appAxios.interceptors.request.use(
    (config) => {
        const token = localStorage.surfbuddy_token;
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token; // for Node.js Express back-end
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

appAxios.interceptors.response.use((response) => {
    return response
}, function (error) {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('surfbuddy_token')
      //  window.location.reload('/');
        return Promise.reject(error);
    }
    return Promise.reject(error);
});


export default appAxios;