import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";

import store from "../store";
import { getAnalytics, logEvent } from "firebase/analytics";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },

  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },
  {
    path: "/spot/:slug",
    name: "SpotView",
    component: () => import("../views/SpotView.vue"),
  },
  {
    path: "/vlog/:slug",
    name: "VlgoView",
    component: () => import("../views/VlogView.vue"),
  },
  {
    path: "/vlog",
    name: "VlogIndexView",
    component: () => import("../views/VlogIndexView.vue"),
  },
  {
    path: "/school/:slug",
    name: "SpotView",
    component: () => import("../views/SchoolView.vue"),
  },
  {
    path: "/spots",
    name: "SpotsINdexView",
    component: () => import("../views/SpotsIndexView.vue"),
  },

  {
    path: "/schools",
    name: "SpotsINdexView",
    component: () => import("../views/SchoolsIndexView.vue"),
  },
  {
    path: "/settings",
    name: "SettingsView",
    component: () => import("../views/SettingsView.vue"),
  },
  {
    path: "/map",
    name: "MapView",
    component: () => import("../views/MapView.vue"),
  },
  {
    path: "/map/spot/:spot_slug",
    name: "MapViewSpot",
    component: () => import("../views/MapView.vue"),
  },
  {
    path: "/u/:username",
    name: "MapViewSpot",
    component: () => import("../views/PublicUserProfile.vue"),
  },
  {
    path: "/profile",
    name: "ProfileView",
    component: () => import("../views/ProfileView.vue"),
    children: [
      {
        path: "/",
        name: "ProfileDetailsView",
        component: () => import("../views/profile/ProfileDetails.vue"),
      },
      {
        path: "edit",
        name: "ProfileDetailsEdit",
        component: () => import("../views/profile/ProfileDetailsEdit.vue"),
      },
      {
        path: "spot",
        name: "ProfileSpotView",
        component: () => import("../views/profile/ProfileSpot.vue"),
      },
      {
        path: "school",
        name: "ProfileSchoolView",
        component: () => import("../views/profile/ProfileSchool.vue"),
      },
    ],
  },

  {
    path: "/:slug",
    name: "PageView",
    component: () => import("../views/PageView.vue"),
  },
];
3;
const router = new VueRouter({
  routes,
});

const analytics = getAnalytics();

router.beforeEach(async (to, from, next) => {
  if (!store.state.logged && to.name != "login" && to.name != "register") {
    router.push("/login");
  }
  next();
});

logEvent(analytics, "pageview", {
  page: router.currentRoute.path,
});

router.afterEach((to) => {
  logEvent(analytics, "pageview", {
    page: to.path,
  });
  document.getElementById("app").scrollTo(0, 0);
  const tooltips = document.querySelectorAll(".tooltip");

  // Iteruj przez wszystkie znalezione elementy i usuń je
  tooltips.forEach((tooltip) => {
    tooltip.remove();
  });
});

export default router;
