<template>
    <div>
        <div class="fix-sidebar"></div>
        <div class="topbar d-flex align-items-center justify-content-between">

            <a v-if="user.email" class="sidebar-button text-light ps-2" data-bs-toggle="offcanvas" href="#offcanvasSidebar"
                role="button" aria-controls="offcanvasSidebar">
                <i class=" me-2 bi bi-list"></i>
            </a>
            <a v-if="user.email" class="search-button pe-2" data-bs-toggle="offcanvas" href="#offcanvasSearch" role="button"
                aria-controls="offcanvasSearch">
                <i class=" me-2 bi bi-search"></i>

            </a>


        </div>
        <SearchBar></SearchBar>




        <div class="offcanvas offcanvas-start sidebar-background" tabindex="-1" id="offcanvasSidebar"
            aria-labelledby="offcanvasSidebarLabel">
            <div class="offcanvas-header">
                <img class="mt-2" width="80px" alt="Vue logo" src="../assets/logo.png">

                <a type="button" class="" data-bs-dismiss="offcanvas" aria-label="Close" href="#"> <img class="me-3"
                        alt="Vue logo" src="../assets/icons/back.svg"></a>
            </div>
            <div class="offcanvas-body h-100">
                <div class="surf-h1 text-center mb-5">
                    Hello {{ user.first_name }}!
                </div>
                <div class="d-flex flex-column ">
                    <div class="position-ralative">
                        <img class="maori" src="@/assets/icons/maori_circle.png">
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/" class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/home.svg" width="32px" class="me-2"> Home</router-link>
                        </div>
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/map" class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/map.svg" width="32px" class="me-2"> Map</router-link>
                        </div>
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/spots"
                                class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/spots.svg" width="32px" class="me-2"> Spots</router-link>
                        </div>
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/schools"
                                class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/schools2.svg" width="32px" class="me-2"> Schools</router-link>
                        </div>
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/vlog" class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/vlog.svg" width="32px" class="me-2"> Vlog</router-link>
                        </div>
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/buy-coffe"
                                class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/home.svg" width="32px" class="me-2"> Buy
                                coffe</router-link>
                        </div>
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/membership"
                                class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/membership.svg" width="32px" class="me-2"> Membership</router-link>
                        </div>
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/certificate"
                                class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/marcetmap.svg" width="32px" class="me-2"> Certificate</router-link>
                        </div>
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/profile"
                                class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/profile.svg" width="32px" class="me-2"> Profile</router-link>
                        </div>
                        <div class="mb-4">
                            <router-link @click.native="closeMenu" to="/contact"
                                class=" d-flex align-items-center menu-item ">
                                <img src="@/assets/icons/contact.svg" width="32px" class="me-2"> Contact</router-link>
                        </div>
                    </div>
                    <div class="text-end">
                        <router-link @click.native="closeMenu" to="/settings" class="text-reset text-decoration-none" href="#">
                            <img src="@/assets/icons/settings.svg" width="32px" class="me-2"></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Offcanvas } from 'bootstrap'
import SearchBar from './SearchBar.vue';

export default {
    name: 'AppSidebar',
    components: {
        SearchBar
    },
    data: function () {
        return {
            offcanvas: {}
        }
    },
    computed: {
        user: function () {
            return this.$store.state.user;
        }
    },
    mounted: function () {
        this.offcanvas = new Offcanvas('#offcanvasSidebar')
        window.offcanvas = this.offcanvas;
    },
    methods: {
        closeMenu: function () {
            this.offcanvas.hide();
        }

    }
}
</script>
<style>
.sidebar-button {
    font-size: 40px;
    text-decoration: none;
    color: black;
}

.topbar {
    background-color: #009fe3;
    height: 52px;
    position: fixed;
    top: 0px;
    width: 480px;
    max-width: 100%;
    z-index: 1000;
}

.fix-sidebar {
    height: 52px;
}

.sidebar-background {
    /* Rectangle 12 */


    background-color: #f2f2f2 !important;
    background: linear-gradient(270deg, #239ECE 0%, rgba(231, 233, 235, 0) 56.77%);

}

.menu-item {
    /* Buy Coffee */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px !important;
    line-height: 24px;
    /* identical to box height */

    color: #3D3635;
    text-decoration: none;
}
</style>