import axios from "axios";

axios.defaults.baseURL = 'https://surfbuddy.szymonyo.tk/api/cms/';

export default {
    getSpots: function (params) {
        return axios.get('contents/spots', { params: params });
    },

    getSpot: function (slug) {
        return axios.get('contents/spot/' + slug);

    },
    getSchools: function (params) {
        return axios.get('contents/schools', { params: params });
    },

    getSchool: function (slug) {
        return axios.get('contents/school/' + slug);

    },

    getVlogs: function () {
        return axios.get('contents/vlogs');
    },

    getVlog: function (slug) {
        return axios.get('contents/vlog/' + slug);

    },
    getPage: function (slug) {
        return axios.get('contents/page/' + slug);

    }


}