// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyC2uHu2XKfMwWYLGiB5p_TiaJGIKfUtwg8",

  authDomain: "cosmic-flux-265515.firebaseapp.com",

  projectId: "cosmic-flux-265515",

  storageBucket: "cosmic-flux-265515.appspot.com",

  messagingSenderId: "682827442077",

  appId: "1:682827442077:web:3c61ccfe143d9170343174",

  measurementId: "G-C7092M8XZV"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

export default getAnalytics(app);
// console.log(app);