import './firebase'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { register } from 'swiper/element/bundle';
import youtubeApi from './youtubeApi';
import './registerServiceWorker'
import tooltip from './components/tooltip.js'
import VueSweetalert2 from 'vue-sweetalert2';
import './assets/scss/app.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
// If you don't need the styles, do not connect
import dayjs from 'dayjs'
import 'sweetalert2/dist/sweetalert2.min.css';
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

Vue.use(VueSweetalert2);
Vue.use(tooltip);

// register Swiper custom elements
register();
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    youtubeApi.loadYoutubeApi();
  }
}).$mount('#app')
