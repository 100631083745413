<template>
    <img src="@/assets/icons/europe.svg" v-if="iconName == 'europe' && icon">
    <img src="@/assets/icons/europe.svg" v-else>

</template>

<script>
export default {
    props: ['icon'],
    computed: {
        iconName: function () {
            return this.icon?.toLowerCase().replace(' ', '_');
        }
    }
}
</script>