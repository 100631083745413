<template>
    <div class="w-100 mt-100 d-flex justify-content-end flex-column">

        <div v-if="!form" class="d-flex flex-column align-items-center justify-content-center mb-2 w-100">
            <div class="col-6 mb-4">
                <button @click="form = true" to="/home" class="btn btn-primary d-block w-100">Login</button>
            </div>


            <div class="col-6 mb-5">
                <router-link @click="form = true" to="/register" class="btn btn-outline-white d-block w-100">Sign up</router-link>
            </div>

            <div class="col-10 mb-1">

                <LoginViaFacebook></LoginViaFacebook>
            </div>

            <div class="col-10 mb-3">
                <LoginViaGoogle></LoginViaGoogle>
            </div>

        </div>
        <LoginForm v-if="form"></LoginForm>
    </div>
</template>
<script>
import LoginForm from './LoginForm.vue';
import LoginViaFacebook from './LoginViaFacebook.vue';
import LoginViaGoogle from './LoginViaGoogle.vue';

export default {
    components: { LoginForm, LoginViaFacebook, LoginViaGoogle },
    data() {
        return {
            form: false,
        }
    }
}
</script>
<style>
.loginBtn {
    box-sizing: border-box;
    position: relative;
    /* width: 13em;  - apply for fixed size */
    margin: 0.2em;
    align-items: center;
    width: 100%;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #FFF;
}

.loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
}

.loginBtn:focus {
    outline: none;
}

.loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}


/* Facebook */
.loginBtn--facebook {
    background-color: #4C69BA;
    background-image: linear-gradient(#4C69BA, #3B55A0);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354C8C;
}

.loginBtn--facebook:before {
    border-right: #3C66C4 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
    background-color: #3C66C4;
    color: white;
}


/* Google */
.loginBtn--google {
    /*font-family: "Roboto", Roboto, arial, sans-serif;*/
    background: #DD4B39 !important;
}

.loginBtn--google:before {
    border-right: #BB3F30 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
    background: #E74B37;
}
</style>