import axios from "axios";
import appAxios from './appAxios'

const pureAxios = axios.create({ baseURL: 'https://surfbuddy.szymonyo.tk/app/' })

export default {
    login: function (email, password) {
        let vm = this;
        return new Promise((resolve, reject) => {
            pureAxios.post('auth/login', { email: email, password: password }).then(response => {
                localStorage.setItem('surfbuddy_token', response.data.token);
                localStorage.setItem('surfbuddy_expired', response.data.expired_at);
                appAxios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('surfbuddy_token');
                vm.getMe().then(response => {
                    resolve(response);
                }, (err) => {
                    reject(err)
                })
            }, (err) => { reject(err) })
        });
    },
    googleLogin: function (token) {
        let vm = this;
        return new Promise((resolve, reject) => {
            pureAxios.post('auth/google/login', { token: token }).then(response => {
                localStorage.setItem('surfbuddy_token', response.data.token);
                localStorage.setItem('surfbuddy_expired', response.data.expired_at);
                appAxios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('surfbuddy_token');
                vm.getMe().then(response => {
                    resolve(response);
                }, (err) => {
                    reject(err)
                })
            }, (err) => { reject(err) })
        });
    },
    googleRegister: function (token) {
        let vm = this;
        return new Promise((resolve, reject) => {
            pureAxios.post('auth/google/register', { token: token }).then(response => {
                localStorage.setItem('surfbuddy_token', response.data.token);
                localStorage.setItem('surfbuddy_expired', response.data.expired_at);
                appAxios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('surfbuddy_token');
                vm.getMe().then(response => {
                    resolve(response);
                }, (err) => {
                    reject(err)
                })
            }, (err) => { reject(err) })
        });
    },
    facebookLogin: function (token) {
        let vm = this;
        return new Promise((resolve, reject) => {
            pureAxios.post('auth/facebook/login', { token: token }).then(response => {
                localStorage.setItem('surfbuddy_token', response.data.token);
                localStorage.setItem('surfbuddy_expired', response.data.expired_at);
                appAxios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('surfbuddy_token');
                vm.getMe().then(response => {
                    resolve(response);
                }, (err) => {
                    reject(err)
                })
            }, (err) => { reject(err) })
        });
    },
    facebookRegister: function (token) {
        let vm = this;
        return new Promise((resolve, reject) => {
            pureAxios.post('auth/facebook/register', { token: token }).then(response => {
                localStorage.setItem('surfbuddy_token', response.data.token);
                localStorage.setItem('surfbuddy_expired', response.data.expired_at);
                appAxios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('surfbuddy_token');
                vm.getMe().then(response => {
                    resolve(response);
                }, (err) => {
                    reject(err)
                })
            }, (err) => { reject(err) })
        });
    },
    register(data) {
        return pureAxios.post('auth/register', data);
    },
    getMe: function () {
        return appAxios.get('api/me');
    },
    checkLogged: function () {
        let vm = this;
        return new Promise((resolve, reject) => {
            if (!localStorage.surfbuddy_token) {
                reject({}, 'notoken');
            }
            vm.getMe().then(response => {
                resolve(response)
            }, (error) => {
                console.log('here')
                reject(error)
            })
        })
    },

    uploadPhoto: function (formData) {
        return appAxios.post('api/photo', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    setHomespot(content_id) {
        return appAxios.put('api/homespot', { content_id: content_id })
    },
    setHomeschool(content_id) {
        return appAxios.put('api/homeschool', { content_id: content_id })
    },
    toggleFavSpot(content_id) {
        return appAxios.put('api/favourite/spot', { content_id: content_id })
    },
    toggleFavSchool(content_id) {
        return appAxios.put('api/favourite/school', { content_id: content_id })
    },
    getFavSpot() {
        return appAxios.get('api/favourite/spot')
    },
    getFavSchool() {
        return appAxios.get('api/favourite/school')
    },
    saveProfile: function (data) {
        return appAxios.post('api/me', data);
    },
    postSchoolOpinion: function (data) {
        return appAxios.post('api/school/opinion', data);
    },
    getSchoolOpinion: function (post_id) {
        return appAxios.get('api/school/' + post_id + '/opinion');
    },
    postSpotOpinion: function (data) {
        return appAxios.post('api/spot/opinion', data);
    },
    getSpotOpinion: function (post_id) {
        return appAxios.get('api/spot/' + post_id + '/opinion');
    },
    getPublicProfile: function (username) {
        return appAxios.get('api/user/' + username);
    },
    getSearchConfig: function () {
        return appAxios.get('api/search/config');
    },
    
}