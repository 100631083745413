

<template>
  <div id="app" class="h-100 position-relative overflow-auto"
    :class="{ 'app-background': !$store.state.user.email && loaded }">
    <Sidebar v-if="loaded && $store.state.user.email"></Sidebar>
    <Transition>
      <AppLoader v-if="!loaded"></AppLoader>
      <router-view :key="$route.path" class="app-container" v-else />
    </Transition>
  </div>
</template>

<script>
import AppLoader from './components/AppLoader.vue';
import Sidebar from './components/Sidebar.vue';
import appApi from './components/api/appApi';
export default {
  components: { AppLoader, Sidebar },
  data: function () {
    return {
      loaded: false,
    }
  },
  mounted() {
    setTimeout(function () {
      this.loaded = true;
    }.bind(this), 4500);

    appApi.checkLogged().then(response => {
      this.$store.commit('setUser', response.data.user);
      this.$store.commit('setFavourites', response.data.favourites);
      this.$store.commit('setUserdata', response.data.userdata);

      if (this.$route.name == 'login') {
        this.$router.push('/');
      }
    }, () => {
      // this.$router.push('/login')
    })
  }
}
</script>
<style>
#app {
  max-width: 480px;
  margin: auto;
  height: 100%;
  background-color: white;
}

body {
  height: 100%;
  background-color: rgb(155, 155, 155) !important;
}

.app-container {
  height: calc(100% - 65px);
  max-height: calc(100% - 65px);

}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

body {
  font-family: Poppins !important;
}

.app-background {
background-color: white;
}

.btn-primary {
  background-color: #009fdc !important;
}
</style>