<template>
    <button @click="doLogin" class="loginBtn btn text-white loginBtn--facebook mb-2">
        Login with Facebook
    </button>
</template>

<script>
import appApi from './../api/appApi';

export default {
    created() {
        this.setupStartScript();
        this.importScript();
    },
    data() {
        return {
            token: {}
        }
    },
    methods: {

        setupStartScript: function () {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: process.env.VUE_APP_FB_APP_ID,
                    cookie: true, // Enable cookies to allow the server to access the session.
                    xfbml: true, // Parse social plugins on this webpage.
                    version: 'v17.0' // Use this Graph API version for this call.
                });
            };

        },
        importScript: function () {
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));

        },

        doLogin: function () {
            let vm = this;
            window.FB.login(function (response) {
                if (response.status === 'connected') { // Logged into your webpage and Facebook.
                    vm.loginAttempt(response.authResponse.accessToken)
                } else { // Not logged into your webpage or we are unable to tell.
                    alert('Failed login via facebook')
                }
            }, {
                scope: 'public_profile,email'
            });

        },
        loginAttempt: function (token) {
            this.token = token;
            appApi.facebookLogin(token).then(response => {
                this.$store.commit('setUser', response.data.user);
                this.$router.push('/');
            }, this.loginError)
        },
        loginError: function (error) {
            if (error.response.data.error == 'no_registered') {
                this.loginNeedRegister();
            }
        },
        loginNeedRegister: function () {
            this.$swal.fire({
                title: 'Do you accept terms and conitiondois?',
                showCancelButton: true,
                confirmButtonText: 'Accept',
                denyButtonText: `Don't accept`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.register()
                }
            })
        },
        register: function () {
            appApi.facebookRegister(this.token).then(response => {
                this.$store.commit('setUser', response.data.user);
                this.$router.push('/profile');
            }, () => {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            });

        }
    }
}
</script>