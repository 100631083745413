<template>
    <div>


        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasSearch" aria-labelledby="offcanvasSearchLabel">
            <div class="offcanvas-header">
                Search
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>

            </div>
            <div class="offcanvas-body h-100">
                <div class="d-flex flex-column justify-content-start h-100">
                    <div class="form-floating mb-3">
                        <input @input="changed" v-model="search" type="text" class="form-control" id="floatingInput"
                            placeholder="name@example.com">
                        <label for="floatingInput">Search spots, schools</label>
                    </div>
                    <div v-if="more && config">
                        <div class="form-floating mb-3">
                            <select class="form-select" id="floatingSelect" @change="load(true)" v-model="fields.country"
                                aria-label="Floating label select example">
                                <option value=""></option>

                                <option v-for="item, key in config.country " :key="key" :value="item">{{ item }}</option>

                            </select>
                            <label for="floatingSelect">Country</label>
                        </div>
                        <div class="form-floating mb-3">
                            <select class="form-select" id="floatingSelect" @change="load(true)" v-model="fields.continent"
                                aria-label="Floating label select example">
                                <option value=""></option>
                                <option v-for="item, key in config.continent " :key="key" :value="item">{{ item }}</option>

                            </select>
                            <label for="floatingSelect">Continent</label>
                        </div>
                        <div class="form-floating mb-3">
                            <select class="form-select" id="floatingSelect" v-model="sport" @change="load(true)"
                                aria-label="Floating label select example">
                                <option value=""></option>
                                <option value="Windsurfing">Windsurfing</option>
                                <option value="Kitesurfing">Kitesurfing</option>
                                <option value="Wingfoiling">Wingfoiling</option>

                            </select>
                            <label for="floatingSelect">Sport</label>
                        </div>

                        <a href="#" @click.prevent="clear">clear filters</a>
                    </div>
                    <div class="text-end">
                        <a @click.prevent="filters_toggle" href="#" class="text-decoration-none text-reset">{{ more ?
                            "-less_filters" : ' + more filters' }}</a>
                    </div>
                    <div>
                        <div v-if="loaded" class="d-flex justify-content-center mb-4">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="text-center" v-if="items.length == 0 && schools.length==0 && search.length > 0 && !loaded">
                            No results
                        </div>
                        <div v-if="!loaded">

                            <div v-for="item, key in items" :key="key + item.uuid">
                                <router-link class="text-reset text-decoration-none" :to="'/spot/' + item.slug">
                                    <SpotListItem @clicked="offcanvas.hide()" :item="item"></SpotListItem>
                                    <!-- <SchoolListItem></SchoolListItem> -->
                                </router-link>
                            </div>
                            <div v-for="item, key in schools" :key="key + item.uuid">
                                <router-link class="text-reset text-decoration-none" :to="'/spot/' + item.slug">
                                    <SchoolListItem @clicked="offcanvas.hide()" :item="item"></SchoolListItem>
                                    <!-- <SchoolListItem></SchoolListItem> -->
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


// import SchoolListItem from './schools/SchoolListItem.vue';
import SpotListItem from './spots/SpotListItem.vue';

import { Offcanvas } from 'bootstrap'
import cmsApi from './api/cmsApi'
import SchoolListItem from './schools/SchoolListItem.vue';
import appApi from './api/appApi'
export default {
    name: "SearchBar",
    data: function () {
        return {
            fields: {
                country: '',
                continent: '',
            },
            search: "",
            sport: '',
            offcanvas: {},
            items: [],
            more: false,
            schools: [],
            loaded: false,
            config: null,
        };
    },
    computed: {
        user: function () {
            return this.$store.state.user;
        }
    },

    mounted: function () {
        this.offcanvas = new Offcanvas("#offcanvasSearch");
        window.offcanvas = this.offcanvas;
        this.load()
        appApi.getSearchConfig().then(response => {
            this.config = response.data.data;
        })
    },
    methods: {
        filters_toggle: function () {
            this.more = !this.more;
            if (!this.more) {
                this.clear();
            }
        },
        clear: function () {
            this.fields = {
                country: '',
                continent: '',
            }
            this.sport = '';
        },
        load: function (preventInputClear = false) {

            if (this.search == '' && !preventInputClear) {
                this.items = []
                this.schools = []

                this.loaded = false;
                return;
            }
            let obj = {}
            if(this.sport){   
                obj[this.sport] = 1
            }
            cmsApi.getSpots({ q: this.search, q_fields: Object.assign(obj, this.fields) }).then(response => {
                this.items = response.data.data.data;
                this.loaded = false;

            }, () => {
                this.loaded = false;
            });
            cmsApi.getSchools({ q: this.search, q_fields: Object.assign(obj, this.fields) }).then(response => {
                this.schools = response.data.data.data;
                this.loaded = false;

            }, () => {
                this.loaded = false;
            });
        },
        closeMenu: function () {
            this.offcanvas.hide();
        },
        changed: function () {
            if (!this.loaded) {
                this.loaded = true;
                setTimeout(function () {
                    this.load()
                }.bind(this), 1000)
            }
        }
    },
    components: { SpotListItem, SchoolListItem }
}
</script>


<style>
.search-button {
    font-size: 30px;
    text-decoration: none;
    color: white;
}
</style>