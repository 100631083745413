<template>
    <button @click.prevent="doLogin" class="btn text-light loginBtn  loginBtn--google mb-3">
        Login with Google
    </button>
</template>
<script>
import appApi from './../api/appApi';

export default {
    created() {
        this.importScript();
    },
    data() {
        return {
            token: {}
        }
    },
    methods: {
        importScript: function () {
            let scriptEle = document.createElement("script");
            scriptEle.setAttribute("src", 'https://accounts.google.com/gsi/client?onload=googleStart');
            scriptEle.setAttribute("type", "text/javascript");
            document.body.appendChild(scriptEle);
            scriptEle.addEventListener("load", this.scriptLoaded);
        },
        scriptLoaded: function () {
            window.google.accounts.id.initialize({
                client_id: process.env.VUE_APP_GOOGLE_ID,
                callback: this.loginAttempt
            });

        },
        doLogin: function () {
            document.cookie = 'g_state={}';
            setTimeout(function () {
                window.google.accounts.id.prompt();
            }, 500);
        },
        loginAttempt: function (token) {
            this.token = token;
            appApi.googleLogin(token.credential).then(response => {
                this.$store.commit('setUser', response.data.user);
                this.$router.push('/');
            }, this.loginError)
        },
        loginError: function (error) {
            if (error.response.data.error == 'no_registered') {
                this.loginNeedRegister();
            }
        },
        loginNeedRegister: function () {
            this.$swal.fire({
                title: 'Do you accept terms and conitiondois?',
                showCancelButton: true,
                confirmButtonText: 'Accept',
                denyButtonText: `Don't accept`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.register()
                }
            })
        },
        register: function () {
            appApi.googleRegister(this.token.credential).then(response => {
                this.$store.commit('setUser', response.data.user);
                this.$router.push('/profile');
            }, () => {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            });

        }
    }
}
</script>